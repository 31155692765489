body {
  margin-bottom: 1rem;
}

* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 50%;
  }
}

.container1 {
  margin-left: 2vw;
  margin-top: 2vh;
}

.card {
  margin: 2rem;
  box-shadow: 0px 5px 15px 7px lightgrey;
}

.card:hover {
  box-shadow: 0px 5px 15px 7px rgb(136, 140, 150);
}

.about-card {
  border-left: 1px solid #dfdfdf !important;
}

img {
  height:300px;
  width: 300px;
  object-fit: contain;
}

ul.b {
  list-style-type: circle;
}

.list {
  margin-top: 3rem;
}

a {
  color:darkslategray;
}

a:hover {
  color:blue;
}
